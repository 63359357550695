import React from "react";
import './unit.css';

// import { Carousel } from "react-bootstrap";
const ReUBCLIB = () => (
    <>
        <header id="ubc7-header" className="row-fluid expand" role="banner">
            <div className="span1">
                <div id="ubc7-logo">
                    <a href="http://www.ubc.ca" title="The University of British Columbia (UBC)">The University of British
                        Columbia
                    </a>
                </div>
            </div>
            <div className="span2">
                <div id="ubc7-apom">
                    <a href="https://cdn.ubc.ca/clf/ref/aplaceofmind" title="UBC a place of mind">UBC - A Place of Mind</a>
                </div>
            </div>
            <div className="span9" id="ubc7-wordmark-block">
                <div id="ubc7-wordmark">
                    <a href="http://www.ubc.ca" title="The University of British Columbia (UBC)">
                        The University of British Columbia
                    </a>
                </div>
                <div id="ubc7-global-utility">
                    <button type="button" data-toggle="collapse" data-target="#ubc7-global-menu" control-id="ControlID-4"><span>UBC Search</span>
                    </button>
                    <noscript>
                        <a id="ubc7-global-utility-no-script" href="http://www.ubc.ca/" title="UBC Search">UBC Search</a>
                    </noscript>
                </div>
            </div>
        </header>
        <div id="ubc7-unit" className="row-fluid expand">
            <div className="span12">
                <div className="navbar">
                    <a className="btn btn-navbar" data-toggle="collapse" data-target="#ubc7-unit-navigation">
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                    </a>
                </div>

                <div id="ubc7-unit-name">
                    <a href="https://www.library.ubc.ca"><span id="ubc7-unit-identifier" className="no-umbrella">Library</span>
                    </a>
                </div>
            </div>
        </div>
    </>
);

export default ReUBCLIB;