import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

// import { EarthCanvas } from "./canvas";

const ContactForm = () => {
    const formRef = useRef();
    const [form, setForm] = useState({
        name: "",
        email: "",
        message: "",
    });

    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { target } = e;
        const { name, value } = target;

        setForm({
            ...form,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        if (form.name === '' || form.email === '' || form.message === '') {
            setLoading(false);
            alert("Kindly ensure that all fields in the form are completed.");
            return;
        }

        emailjs
            .send(
                process.env.REACT_APP_EMAILJS_SERVICE_ID,
                process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
                {
                    from_name: form.name,
                    to_name: "Yusheng Ding",
                    from_email: form.email,
                    to_email: "dingyush@student.ubc.ca",
                    message: form.message,
                },
                process.env.REACT_APP_EMAILJS_PUBLIC_KEY
            )
            .then(
                () => {
                    setLoading(false);
                    alert("I appreciate it. I'll await your response when you're ready to get in touch.");

                    setForm({
                        name: "",
                        email: "",
                        message: "",
                    });
                },
                (error) => {
                    setLoading(false);
                    console.error(error);

                    alert("Oops, an issue occurred. Kindly give it another attempt.");
                }
            );
    };

    return (
        <div
            className='flex-[0.75] bg-black-100 p-8 rounded-2xl bg-orange-400 dark:bg-orange-700'
        >
            <h2 className='text-center my-5 text-4xl font-bold'>Contact Me</h2>
            <p className='text-center' >Get in touch</p>

            <form
                ref={ formRef }
                onSubmit={ handleSubmit }
                className='mt-12 flex flex-col gap-8'
            >
                <label className='flex flex-col'>
                    <span className=' font-medium mb-4'>Your Name</span>
                    <input
                        type='text'
                        name='name'
                        value={ form.name }
                        onChange={ handleChange }
                        placeholder="What's your good name?"
                        className=' py-4 px-6 placeholder:text-secondary  rounded-lg outline-none border-none font-medium'
                    />
                </label>
                <label className='flex flex-col'>
                    <span className=' font-medium mb-4'>Your email</span>
                    <input
                        type='email'
                        name='email'
                        value={ form.email }
                        onChange={ handleChange }
                        placeholder="What's your web address?"
                        className=' py-4 px-6 placeholder:text-secondary  rounded-lg outline-none border-none font-medium'
                    />
                </label>
                <label className='flex flex-col'>
                    <span className=' font-medium mb-4'>Your Message</span>
                    <textarea
                        rows={ 7 }
                        name='message'
                        value={ form.message }
                        onChange={ handleChange }
                        placeholder='What you want to say?'
                        className=' py-4 px-6 placeholder:text-secondary  rounded-lg outline-none border-none font-medium'
                    />
                </label>

                <button
                    type='submit'
                    className='btn btn-primary py-3 px-8 outline-none w-fit font-bold shadow-md shadow-primary mx-auto'
                >
                    { loading ? "Sending..." : "Send" }
                </button>
            </form>
        </div>





        // <div
        //     className={ `xl:mt-12 flex xl:flex-row flex-col-reverse gap-10 overflow-hidden ` }
        // >
        //     <div
        //         className='flex-[0.75] bg-black-100 p-8 rounded-2xl bg-orange-400 dark:bg-orange-700'
        //     >
        //         <h2 className='text-center my-5'>Contact Me</h2>
        //         <p className='text-center' >Get in touch</p>

        //         <form
        //             ref={ formRef }
        //             onSubmit={ handleSubmit }
        //             className='mt-12 flex flex-col gap-8'
        //         >
        //             <label className='flex flex-col'>
        //                 <span className=' font-medium mb-4'>Your Name</span>
        //                 <input
        //                     type='text'
        //                     name='name'
        //                     value={ form.name }
        //                     onChange={ handleChange }
        //                     placeholder="What's your good name?"
        //                     className='bg-tertiary py-4 px-6 placeholder:text-secondary  rounded-lg outline-none border-none font-medium'
        //                 />
        //             </label>
        //             <label className='flex flex-col'>
        //                 <span className=' font-medium mb-4'>Your email</span>
        //                 <input
        //                     type='email'
        //                     name='email'
        //                     value={ form.email }
        //                     onChange={ handleChange }
        //                     placeholder="What's your web address?"
        //                     className='bg-tertiary py-4 px-6 placeholder:text-secondary  rounded-lg outline-none border-none font-medium'
        //                 />
        //             </label>
        //             <label className='flex flex-col'>
        //                 <span className=' font-medium mb-4'>Your Message</span>
        //                 <textarea
        //                     rows={ 7 }
        //                     name='message'
        //                     value={ form.message }
        //                     onChange={ handleChange }
        //                     placeholder='What you want to say?'
        //                     className='bg-tertiary py-4 px-6 placeholder:text-secondary  rounded-lg outline-none border-none font-medium'
        //                 />
        //             </label>

        //             <button
        //                 type='submit'
        //                 className='bg-tertiary py-3 px-8 rounded-xl outline-none w-fit  font-bold shadow-md shadow-primary'
        //             >
        //                 { loading ? "Sending..." : "Send" }
        //             </button>
        //         </form>
        //     </div>

        //     <div
        //         className='xl:flex-1 xl:h-auto md:h-[550px] h-[350px]'
        //     >
        //         {/* <EarthCanvas /> */ }
        //     </div>
        // </div>
    );
};

export default ContactForm;
