import React, { useState, useEffect, useRef } from 'react';
import { AiFillRobot, AiOutlineUser } from 'react-icons/ai';
const ChatBot = ({ autoScroll = true }) => {
    const [chatContainer, setChatContainer] = useState([])
    // const [loadInterval, setLoadInterval] = useState()
    const [prompt, setPrompt] = useState('')
    const [hideChatBotInfo, setHideChatBotInfo] = useState(false);
    const form = document.querySelector('form')
    const dummy = useRef(null);
    // const loader = (element) => {
    //     element.textContent = ''
    //     setLoadInterval(
    //         setInterval(() => {
    //             // Update the text content of the loading indicator
    //             element.textContent += '.'
    //             // If the loading indicator has reached three dots, reset it
    //             if (element.textContent === '....') {
    //                 element.textContent = ''
    //             }
    //         }, 300)
    //     )
    // }

    // generate unique ID for each message div of bot
    // necessary for typing text effect for that specific reply
    // without unique ID, typing text will work on every element
    const generateUniqueId = () => {
        const timestamp = Date.now()
        const randomNumber = Math.random()
        const hexadecimalString = randomNumber.toString(16)

        return `id-${timestamp}-${hexadecimalString}`
    }

    const chatStripe = (isAi, value, uniqueId) => {
        let today = new Date();
        return (
            <div className={ " chat " + (isAi ? " chat-start " : " chat-end ") } key={ uniqueId }>
                <div className="chat-image ">
                    <div className="w-10 rounded-full content-center">
                        { isAi ? <AiFillRobot size={ 35 } /> : <AiOutlineUser size={ 35 } /> }
                    </div>
                </div>
                <div className="chat-header">
                    { isAi ? "ChatBot" : "You" }
                    <time className="text-xs opacity-50 pl-1">
                        { today.toLocaleString('en-US', { timeZone: 'America/Vancouver' }).substring(11, 19) }
                    </time>
                </div>
                <div className={ "chat-bubble" + (isAi ? " chat-bubble-secondary" : " chat-bubble-primary") }>
                    { value }
                </div>
            </div>
        )
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const data = new FormData(form)
        form.reset()
        //count the form data length if more than 1000 characters, alert user
        if (data.get('prompt').length > 200) {
            alert('Please limit your message shorter than a Tweet (280 characters), thank you!')
            return;
        }

        // user's chatstripe
        let uniqueId = generateUniqueId()

        const chatContainerCopy = [...chatContainer]
        chatContainerCopy.push(chatStripe(false, prompt, uniqueId))
        setChatContainer(chatContainerCopy)

        // to clear the textarea input 
        setPrompt('')

        if (autoScroll) { scrollToBottom() }
        // bot's chatstripe
        const response = await fetch('https://chatbot-0727.onrender.com', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                prompt: data.get('prompt')
            })
        })

        if (response.ok) {
            const data = await response.json()
            const parsedData = data.bot.trim() // trims any trailing spaces/'\n' 
            uniqueId = generateUniqueId()

            chatContainerCopy.push(chatStripe(true, parsedData, uniqueId))
            setChatContainer(chatContainerCopy.slice())

        } else {
            const err = await response.text()
            alert(err)
        }

    }
    const scrollToBottom = () => {
        // const element = document.getElementById("box");
        dummy.current.scrollIntoView({ behavior: "smooth" });
    }


    // refresh when chat container changes
    useEffect(() => {
        if (autoScroll) { scrollToBottom() }
    }, [chatContainer])

    useEffect(() => {
        setChatContainer([chatStripe(true, 'Hi, I am ChatBot. How can I help you?', 'id-0')])

    }, [])
    return (
        <div className='flex-[0.75] bg-black-100 p-4 rounded-2xl bg-orange-400 dark:bg-orange-700'>
            <div className='bg-apple__orange_dark dark:bg-orange-500 p-4 rounded-2xl'>


                { !hideChatBotInfo ? <div className='hover:cursor-pointer' onClick={ () => (setHideChatBotInfo(!hideChatBotInfo)) }>
                    <h1 className='text-3xl font-bold text-center '>TL;DR? Ask me about Yusheng
                    </h1>
                    <p>Due to cold startup of the server, please be patient and wait for 30 seconds for the first response, the follow-up response will be instantaneous.
                    </p>
                    <p className='text-xs'>Disclaimer: Please note that this feature is still undergoing refinement and improvement. The information provided by the ChatBot may be inaccurate. It is advisable to proceed with caution and verify the information.

                    </p>
                    <p className='underline text-center'>Tap to Collapse</p>

                </div>
                    : <h1 className='text-3xl font-bold text-center cursor-pointer'
                        onClick={ () => (setHideChatBotInfo(!hideChatBotInfo)) }>
                        ChatBot
                    </h1>
                }

            </div>
            <div className='container mx-auto bg-apple__orange dark:bg-apple__orange_dark z-50'>
                <div className=' mt-0 h-96 overflow-y-scroll '>
                    { chatContainer }

                    <div id="button" ref={ dummy }></div>
                </div>
                <form onSubmit={ handleSubmit } className="w-full input-group " >
                    {/* form-control */ }
                    <textarea
                        className='input input-bordered textarea resize-y w-5/6 py-4 px-6 placeholder:text-secondary rounded-lg outline-none border-none font-medium'
                        name="prompt"
                        rows="1"
                        placeholder="Type Your Questions Here..."
                        value={ prompt }
                        onChange={ (e) => setPrompt(e.target.value) }
                        required
                    />
                    <button type="submit" className='btn btn-primary w-1/6'>
                        Send
                    </button>
                </form>
            </div>

        </div>
    )
}

export default ChatBot;