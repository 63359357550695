import React from "react";
import './MainPage.css';

import { AboutUs, FindUs, Footer, Gallery, Header, Intro, SpecialMenu, Welcome, AsciiWelcome, ContentBox, Carousel } from '../../container';
import { Navbar, Loading } from "../../components";
import { Suspense } from "react";
// import { Carousel } from "react-bootstrap";
const Mainpage = () => (
    <>

        <Navbar />

        {/* <Welcome /> */}
        {/* Errors <AsciiWelcome /> */ }
        <Header />
        {/* <SpecialMenu />
		 */}
        <Suspense fallback={ <Loading /> }>
            <AboutUs />
        </Suspense>


        <FindUs />
        <Footer />

    </>

);

export default Mainpage;