import React from 'react';

import './Gallery.css';

const Gallery = () => (
  <div>
    Gallery
  </div>
);

export default Gallery;
