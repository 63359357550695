import React from 'react';
// import ReactDOM from 'react-dom';
// import { ThemeProvider } from './components/NightTheme/ThemeContext';
// import Background from './components/NightTheme/Background';
// import { BrowserRouter, Routes, Route } from 'react-router-dom';



import './index.css';
import App from './App';
// import { Projects, Contact } from './pages';
import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
// const container = document.body;
const root = createRoot(container); 
root.render(<App Tab="home" />);

// ReactDOM.render(
//     // <React.StrictMode>
//     //     <BrowserRouter>
//     //         <ThemeProvider>
//     //             <Background>
//     //                 <Routes>
//     //                     <Route path="/" element={ <App />} />
//     //                     <Route path="projects" element={ <Projects /> } />
//     //                     <Route path="contact" element={ <Contact /> } />

//     //                 </Routes>

//     //             </Background>
//     //         </ThemeProvider>
//     //     </BrowserRouter>
//     // </React.StrictMode>
//     <App />
//     , document.getElementById('root'));

