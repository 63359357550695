import React, { Component } from 'react'
import CardInfo from './CardInfo'
import { urlFor, client } from "../../client";
import { click } from '@testing-library/user-event/dist/click';
import { Link } from "react-router-dom";


export default class Card extends Component {

    constructor (props) {
        super(props);
        this.state = { clicked: false, opacity: 0 };
        this.updateState = this.updateState.bind(this);

    }
    updateState = () => {
        this.setState({
            clicked: !this.state.clicked,
            opacity: this.state.opacity === 0 ? 0.7 : 0,
        });
    }


    render() {

        const placeholderSrc = 'https://via.placeholder.com/200x200?text=Intentional+Blank'
        const { about } = this.props;

        const { projectTitle, projectSubtitle, projectDescription, projectDetail, link, image, imagealt, backupArray, selected, markdownFile } = about
        let hasImg = true;
        try {
            urlFor(image).url();
        }
        catch (error) {
            hasImg = false;
        }
        const maxStrlen = 80;
        return (
            <>
                <div className="select-none dark:bg-sky-900 shrink-0 snap-center px-4 mx-10 hover:scale-110 rounded dark:shadow-white shadow-md transform transition duration-y"  >
                    {/* {onClick={ alert(projectName)}  } */ }
                    <div className='overlay-container rounded -translate-x-4 w-full h-full transform transition duration-y'
                        onClick={ this.updateState }
                        style={ { opacity: this.state.opacity, background: "black" } }>
                        { this.state.clicked &&
                            <Link className=' w-1/3 overlay-container align-middle d-block transform rounded -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 bg-blue-500 hover:bg-blue-700 text-black font-bold py-2 px-4 custom__button text-center'
                                to={ "/projects/" + about.slug.current } key={ about.slug.current }>
                                Detail
                            </Link>

                        }



                        {/* { this.state.clicked &&
                            <button
                                className='w-1/3 h-20 overlay-container align-middle d-block transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 bg-blue-500 hover:bg-blue-700 text-black font-bold py-2 px-4 custom__button'
                            >
                                <Link className='text-center'
                                    to={ "/projects/" + about.slug.current } key={ about.slug.current }>
                                    Detail
                                </Link>

                            </button>
                        } */}
                    </div>

                    <div className='grid-rows-2 grid w-full '>
                        <div className="card-carousel-img-container w-full my-3">
                            {
                                hasImg ?
                                    <img className="object-contain card-carousel-img" src={ urlFor(image).url() } alt={ imagealt } />
                                    :
                                    <img className="object-contain card-carousel-img" src={ placeholderSrc } alt="No image, image placeholder" />

                            }
                        </div>

                        <div className='row-auto grid grid-row-2 justify-center my-3 w-72' >
                            {/* inline-size: 150px;
                        overflow-wrap: break-word; */}

                            <h4 className='card-carousel-title break-words ' style={ { "inlineSize": "300px" } } >
                                { about.projectTitle.length > maxStrlen ? (about.projectTitle.substring(0, maxStrlen) + "...") : about.projectTitle }
                            </h4>
                            <h5 className='card-carousel-subtitle break-words ' style={ { "inlineSize": "300px" } }>
                                { about.projectSubtitle.length > maxStrlen ? (about.projectSubtitle.substring(0, maxStrlen) + "...") : about.projectSubtitle }
                            </h5>
                            <p className='card-carousel-info break-words' style={ { "inlineSize": "300px" } }>
                                { about.projectDescription.length > maxStrlen ? (about.projectDescription.substring(0, maxStrlen) + "...") : about.projectDescription }
                            </p>

                        </div>
                        {/* { selected && <CardInfo title={ title } subTitle={ subTitle } link={ link } /> } */ }
                    </div>
                </div>
            </>
        )
    }
}
