import React, { Component, useState, useEffect } from 'react'
import Card from './Card'
import "./Carousel.css"
// import webScreenshot from '../assets/images/webScreenshot.png'
import { urlFor, client } from "../../client";
export default class Carousel extends Component {
    constructor (props) {
        super(props);
        this.state = {
            abouts: [],
        };

    }

    componentDidMount() {
        const query = '*[_type == "abouts"]';
        client.fetch(query).then((data) => {
            this.setState({ abouts: data });
        });
    }

    // handleCardClick = (index, card) => {
    //     let items = [...this.state.items];
    //     abouts[index].selected = abouts[index].selected ? false : true

    //     abouts.forEach(item => {
    //         if (abouts.index !== index) {
    //             abouts[index].selected = false;
    //         }
    //     })
    //     this.setState({
    //         abouts
    //     })
    // }

    // makeItems = (abouts) => {
    //     { console.log(abouts) }

    //     return abouts.map((about, index) => {
    //         return <Card about={ about } key={ index } />
    //         // click={ (e => this.handleCardClick(index, e)) } 
    //     })
    // }


    render() {
        const { abouts } = this.state;


        // abouts.map((about, index) => {
        //     console.log(about.image);
        //     console.log(index);
        // })
        return (
            <>
            <h2 className='text-center text-6xl mt-6'>Projects</h2>
                <div className='md:container md:mx-auto mb-6' >
                    <div className="overflow-x-auto snap-proximity relative snap-x md:justify-between flex " >

                        { abouts.map((about, index) => (
                            <div
                                // whileInView={ { opacity: 1 } }
                                // whileHover={ { scale: 1.1 } }
                                // transition={ { duration: 0.5, type: 'tween' } }
                                className="shrink-0 snap-center my-20"
                                key={ about.projectTitle + index }>
                                <Card about={ about } key={ index } />
                                {/* {console.log(about)} */ }
                                {/* <img src={ urlFor(about.imgUrl) } alt={ about.title } />
                                                <h2 className="bold-text" style={ { marginTop: 20 } }>{ about.title }</h2>
                                                <p className="p-text" style={ { marginTop: 10 } }>{ about.description }</p> */}
                            </div>

                        )) }

                    </div>
                </div >
            </>
        );
    }
}

