import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Header.css';


const Header = () => (
  <div className="app__header app__wrapper section__padding bg-orange-600 dark:text-zinc-200 dark:bg-zinc-800" id="home">
    <div className="app__wrapper_info">
      <h1 className="app__header-h1 dark:text-yellow-600">Hello there!</h1>
      <SubHeading start="I specialize in" title="UX Research., HII., HCI., Game Design." />

      <p className="mb-2 dark:text-yellow-700" > I am Yusheng Ding. I am passionate about understanding and improving the human-information interaction as a UX researcher.
      </p>
      <div className="tooltip tooltip-error" data-tip="Under Development 80%">

        <button type="button" className="cursor-not-allowed bg-slate-800 text-zinc-400 dark:text-zinc-700 custom__button  disabled:opacity-75 focus:outline-none" disabled>
          Explore
        </button>
      </div>
    </div>

    <div className="app__wrapper_img">
      {/* <img src={ images.welcome } alt="header_img" /> */ }
    </div>
  </div>
);

export default Header;
