import React, { useRef, useState, useEffect, useMemo } from 'react';
import { Canvas, extend, useFrame } from '@react-three/fiber';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import * as THREE from 'three';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';

extend({ OrbitControls });

function Text({ font }) {
  const mesh = useRef();
  const [hovered, setHover] = useState(false);
  const [active, setActive] = useState(false);

  useEffect(() => {
    document.body.style.cursor = hovered ? 'pointer' : 'auto';
  }, [hovered]);

  useFrame((state) => {
    const mouseX = (state.mouse.x * state.viewport.width) / 2;
    const mouseY = (state.mouse.y * state.viewport.height) / 2;
    mesh.current.position.x += (mouseX - mesh.current.position.x) * 0.1;
    mesh.current.position.y += (-mouseY - mesh.current.position.y) * 0.1;
  });

  return (
    <mesh
      ref={mesh}
      scale={active ? [1.5, 1.5, 1.5] : [1, 1, 1]}
      onClick={() => setActive(!active)}
      onPointerOver={() => setHover(true)}
      onPointerOut={() => setHover(false)}
    >
      <textGeometry args={['Hello World', { font, size: 30, height: 5 }]} />
      <meshBasicMaterial color={hovered ? 'hotpink' : 'orange'} />
    </mesh>
  );
}

function AsciiText() {
  const [font, setFont] = useState(null);

  useEffect(() => {
    const fontLoader = new FontLoader();
    fontLoader.load(
      'https://raw.githubusercontent.com/mrdoob/three.js/dev/examples/fonts/helvetiker_regular.typeface.json',
      (loadedFont) => {
        setFont(loadedFont);
      }
    );
  }, []);

  return (
    <Canvas style={{ width: '100vw', height: '100vh', background: 'black' }}>
      <ambientLight intensity={0.5} />
      {font ? <Text font={font} /> : <div>Loading...</div>}
    </Canvas>
  );
}

export default AsciiText;
