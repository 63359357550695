
// import knife from '../assets/knife.png';
import logo from '../assets/logo.png';
// import menu from '../assets/menu.png';
// import overlaybg from '../assets/overlaybg.png';
// import spoon from '../assets/spoon.svg';
// import welcome from '../assets/welcome.png';
// import findus from '../assets/findus.png';
// import sign from '../assets/sign.png';
// import quote from '../assets/quote.png';
import enResume from '../assets/enResume.pdf';
import cnResume from '../assets/cnResume.pdf';

import circleimg from '../assets/circle.png'

import wcqrcode from '../assets/wcqrcode.jpg'
export default {
  //   knife,
  logo,
  //   menu,
  //   overlaybg,
  //   spoon,
  //   welcome,
  //   findus,
  //   sign,
  //   quote,
  //   gericht,
  circleimg,
  enResume,
  cnResume,
  wcqrcode,
};
