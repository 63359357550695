// loading neccessary modules and css files
import React, { useState, useEffect, Suspense, lazy } from 'react';
import { Navbar, Loading } from '../../components';
import { Footer, SwiperCard } from '../../container';
// import { MDXProvider } from '@mdx-js/react'
import { compile, evaluate, compileSync, run } from "@mdx-js/mdx"
import * as runtime from "react/jsx-runtime"
// import ReactMarkdown from 'react-markdown'
import { Swiper, SwiperSlide } from "swiper/react";
import { Carousel } from '../../container';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import "./PostContent.css";
import data from "./MdxTest.mdx"


const components = {
    Navbar: () => (<Navbar />),
    SwiperCard: () => (<SwiperCard />),
    Carousel: () => (<Carousel />),
}
const PostContentLocalTest = () => {
    const [postData, setPostData] = useState(data);
    const [exports, setExports] = useState({ default: runtime.Fragment });

    useEffect(() => {
        // client
        //     .fetch(
        //         `*[slug.current == $slug]`,
        //         { slug }
        //     )
        //     .then
        setPostData(data);
        evaluate(postData,
            { useDynamicImport: true, ...runtime })
            .then((exports) => setExports(exports))
            .catch((error) => console.log(String(error))); // temp fix
    }, []);

    const Content = exports.default;
    return (
        <>
            <Navbar />

            <div className='container mx-auto px-2 md:px-10' id='postContent'>
                <Content components={ components } />
            </div>



            <Footer />
        </>
    )
}
export default PostContentLocalTest;
