import React, { useState } from "react";
import { AboutUs, FindUs, Footer, ContactForm, Gallery, Header, Intro, SpecialMenu, Welcome } from '../../container';
import { Navbar } from "../../components";
import { Loading } from "../../components";
// import { Document, Page,PDFViewer, pdfjs } from "react-pdf";
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import "./Contact.css";
import { experiences } from "./Experiences";
import ChatBot from "./ChatBot";
import Resume from "./Resume";
import Timeline from "./Timeline";
import { Suspense } from "react";

const Contact = () => {


    const [resumeSwitch, setResumeSwitch] = useState(true); //false
    const [timelineOrResume, setTimelineOrResume] = useState(true);
    const [chatBotSwitch, setChatBotSwitch] = useState(true);
    const contactCard = () => {
        // This part is deprecated and replaced by other feature. But reserved here for now. 
        return (
            <>
                <div className="flex justify-evenly">
                    {/* <div className="block p-6 rounded-lg shadow-lg bg-white max-w-sm">
                        <h5 className="text-gray-900 text-xl leading-tight font-medium mb-2">Resume</h5>
                        <p className="text-gray-700 text-base mb-4">
                            Check out my resume live below
                        </p>
                        <label className="swap">
                            <input type="checkbox" />
                            <div className="swap-on btn btn-secondary" onClick={ () => { setResumeSwitch(resumeSwitch ? false : true) } }>Resume OFF</div>
                            <div className="swap-off btn btn-secondary" onClick={ () => { setResumeSwitch(resumeSwitch ? false : true) } }>Resume ON</div>
                        </label>

                    </div> */}

                    <div className="flex justify-center w-1/3">

                        <div className="block p-6 rounded-lg shadow-lg bg-white max-w-sm">
                            <h5 className="text-gray-900 text-xl leading-tight font-medium mb-2">Send Me an Email</h5>
                            <p className="text-gray-700 text-base mb-4">
                                Respond Time: 1-2 days

                            </p>
                            <span className="px-3 rounded-full bg-pink-500">
                                <ContactForm label="Send Me an Email" mailto="mailto:dingyush@student.ubc.ca" />
                            </span>

                            {/* <button onClick={ <resumeComp lang="en"/> }
                                    type="button" class=" inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">EN</button> */}
                        </div>
                    </div>
                    <div className="flex justify-center w-1/3">
                        <div className="block p-6 rounded-lg shadow-lg bg-white max-w-sm">
                            <h5 className="text-gray-900 text-xl leading-tight font-medium mb-2">微信我<span className="px-3 rounded-full bg-pink-500 select-all">Yahwooday</span></h5>
                            <p className="text-gray-700 text-base mb-4">
                                响应时间: 1-2天 <br />
                                备注：Portfolio
                            </p>
                            {/* <button onClick={ enRoute }
                                    type="button" class=" inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">CN</button> */}
                        </div>
                    </div>
                </div>

            </>
        )
    }
    return (
        <>
            <Navbar />
            <div className="hero min-h-screen bg-apple__text_gray_dark dark:bg-apple__text_black">
                <div className="hero-content flex-col lg:flex-row-reverse">
                    <img src="" className="max-w-sm rounded-lg shadow-2xl" />
                    <div>
                        <h1 className="text-5xl font-bold">Contact</h1>
                        <p className="py-6">Let's have a talk! </p>
                        {/* <button className="btn btn-primary">Get Started</button> */ }
                    </div>
                </div>
            </div>

            <div className="container mx-auto my-3 ">
                <div className="tabs">
                    <button className={ "tab tab-lg tab-lifted " + (timelineOrResume ? " tab-active dark:text-apple__text_black_strong" : " ") } onClick={ () => { setTimelineOrResume(!timelineOrResume) } }>Timeline</button>
                    <button className={ "tab tab-lg tab-lifted " + (!timelineOrResume ? " tab-active dark:text-apple__text_black_strong" : " ") } onClick={ () => { setTimelineOrResume(!timelineOrResume) } }>Resume</button>
                </div>
                {/* <label className="swap">
                    <input type="checkbox" />
                    <div className="swap-on btn btn-secondary" onClick={ () => { setResumeSwitch(resumeSwitch ? false : true) } }>Resume OFF</div>
                    <div className="swap-off btn btn-secondary" onClick={ () => { setResumeSwitch(resumeSwitch ? false : true) } }>Resume ON</div>
                </label> */}

                {
                    timelineOrResume ?
                        <Timeline experiences={ experiences } />
                        :
                        resumeSwitch &&
                        <div className="container mx-auto">
                            <Suspense fallback={ <Loading skeleton={ "widget" } /> } >
                                <Resume />
                            </Suspense>
                        </div>
                }


            </div>

            <div className='container mx-auto my-3'>
                <div className="tabs">
                    <button className={ "tab tab-lg tab-lifted " + (chatBotSwitch ? " tab-active dark:text-apple__text_black_strong" : " ") } onClick={ () => { setChatBotSwitch(!chatBotSwitch) } }>ChatBot</button>
                    <button className={ "tab tab-lg tab-lifted " + (!chatBotSwitch ? " tab-active dark:text-apple__text_black_strong" : " ") } onClick={ () => { setChatBotSwitch(!chatBotSwitch) } }>Contact</button>
                </div>
                {
                    chatBotSwitch ?
                        <ChatBot autoScroll={false} /> :
                        <ContactForm />

                }
                { contactCard }
                {/* <h2 className='text-center'>Contact Me</h2> */ }
                {/* <button className='text-center'>React Contact Form</button> */ }



            </div>

            <Footer />
            <p className="text-right">NO? Next Oppotunity </p>
            {/* <ChatBot /> */ }
        </>);
};

export default Contact;