import React, { useRef, useEffect} from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdOutlineClose } from 'react-icons/md';
import images from '../../constants/images';
import './Navbar.css';
import Toggle from '../NightTheme/ThemeToggle';

import { Link } from "react-router-dom"

const tempLogostyle = {
	color: "white",
	padding: "10px",
	fontFamily: "var(--font-code)"
};

const Navbar = () => {
	const [toggleMenu, setToggleMenu] = React.useState(false);
	const top = useRef(null);
	useEffect(() => {
		top.current.scrollIntoView({ behavior: 'smooth' })
	}, []);

	return (
		<>
			<div ref={ top }></div>
			<nav className="app__navbar font-black bg-slate-400 text-gray-900 
		dark:bg-slate-800 dark:text-white">
				<Link to="/">
					<div className="app__navbar-logo" style={ tempLogostyle }>
						<img src={ images.logo } alt="app__logo" />

						Yusheng
					</div>
				</Link>
				<ul className="app__navbar-links">
					{/* <li className="p__opensans"><a href="#home">Home</a></li> */ }
					<li className=""><Link to="/">Home</Link></li>
					<li className=""><Link to="/projects">Projects</Link></li>
					{/* <a href="projects">Projects</a> replace between LI */ }
					{/* <li className="p__opensans"><a href="#menu">Menu</a></li>
				<li className="p__opensans"><a href="#awards">Awards</a></li> */}
					<li className=""><Link to="/contact">Contact</Link></li>
				</ul>
				<div className='lg:block hidden'>
					<Toggle />
				</div>
				{/* <button
				className="w-10 h-10 text-center rounded-full text-current hover:text-blue-500 active:text-blue-700 hover:bg-blue-500 hover:bg-opacity-10 dark:hover:text-blue-500 dark:active:text-blue-700 focus:outline-none focus:ring focus:ring-blue-500 focus:ring-opacity-35"
			>			</button> */}

				{/* app__navbar-login */ }
				{/* <a href="#login" className="p__opensans"> EN </a> Log In / Registration
				<div />
				<a href="/" className="p__opensans">ZH</a> */

				/* using it for night theme */ }

				<div className="app__navbar-smallscreen">
					<GiHamburgerMenu color="#fff" fontSize={ 27 } onClick={ () => setToggleMenu(true) } />

					{ toggleMenu && (
						<div className="app__navbar-smallscreen_overlay bg-apple__orange dark:bg-apple__text_black flex__center slide-bottom">
							<div className="overlay__close2">
								<Toggle />

							</div>
							<MdOutlineClose fontSize={ 27 } className="overlay__close" onClick={ () => setToggleMenu(false) }
							/>


							<ul className="app__navbar-smallscreen_links">

								{/* <button
									className="w-10 h-10 text-center rounded-full text-current hover:text-blue-500 active:text-blue-700 hover:bg-blue-500 hover:bg-opacity-10 dark:hover:text-blue-500 dark:active:text-blue-700 focus:outline-none focus:ring focus:ring-blue-500 focus:ring-opacity-35"
								>
								</button> */}

								<li><a href="/" onClick={ () => setToggleMenu(false) }>Home</a></li>

								<li><a href="/projects" onClick={ () => setToggleMenu(false) }>Projects</a></li>
								{/* <li><a href="#menu" onClick={ () => setToggleMenu(false) }>Menu</a></li>
							<li><a href="#awards" onClick={ () => setToggleMenu(false) }>Awards</a></li> */}
								<li><a href="/contact" onClick={ () => setToggleMenu(false) }>Contact</a></li>

							</ul>
						</div>

					) }
				</div>
			</nav></>
	);
};

export default Navbar;