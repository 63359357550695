import React, { useState, useEffect } from "react";
import { AboutUs, FindUs, Footer, ContactForm, Gallery, Header, Intro, SpecialMenu, Welcome } from '../../container';
import { Navbar } from "../../components";
// import { useNavigate } from "react-router-dom";
import images from '../../constants/images';
// import enpdf from "../../assets/enResume.pdf";
// import { Document, Page,PDFViewer, pdfjs } from "react-pdf";
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// import { Document, Outline, Page } from 'react-pdf/dist/esm/entry.webpack5';
import "react-pdf/dist/esm/Page/TextLayer.css";
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import { Link } from "react-router-dom";
import { AiOutlineCloudDownload } from "react-icons/ai";
import "./Resume.css";
const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = url;
// Solution to fix webpack react-pdf from https://github.com/wojtekmaj/react-pdf/issues/971

const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
    standardFontDataUrl: 'standard_fonts/',
};

const Resume = () => {
    const [enfile, setenFile] = useState(images.enResume);
    const [cnfile, setcnFile] = useState(images.cnResume);
    const [display, setDisplay] = useState(true);
    const [pdfScale, setpdfScale] = useState(2);
    const [numPages, setNumPages] = useState(null);
    const [pdfFrame, setPdfFrame] = useState(" md:w-1/2");

    useEffect(() => {
        setpdfScale(1);
        setPdfFrame(" md:w-1/2 ");
    }, [pdfScale < 0.5 || pdfScale > 4]);



    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
        setNumPages(nextNumPages);
    }

    // useEffect(() => {
    //     setPdfFrame(" md:w-2/3 ");
    // }, [pdfScale > 2 && pdfScale < 3]);

    // switch (pdfScale) {
    //     case pdfScale > 2 && pdfScale < 3:
    //         setPdfFrame(" md:w-2/3 ");
    //         break;
    //     case pdfScale > 3 && pdfScale < 4:
    //         setPdfFrame(" md:w-full ");
    //         break;
    //     default:
    //         setPdfFrame(" md:w-1/2 ");
    //         break;
    // }


    let resizeScale = 0.5;


    // ReactDOM.findDOMNode(<instance-of-outermost-component>) 
    //     "react-pdf__Page__annotations annotationLayer"

    return (
        <>
            <div className="inline-grid mx-auto grid-flow-col">
                <div className="btn-group hidden md:inline-flex">

                    <button className="btn btn-accent	 text-2xl text-center  text-current	  "
                        onClick={ () => { setpdfScale((previousScale) => (previousScale - resizeScale)); (pdfScale > 2) ? setPdfFrame(" md:w-full ") : setPdfFrame(" md:w-1/2") } }> - </button>
                    <button className="btn btn-accent	 text-2xl  text-center  text-current    "
                        onClick={ () => { setpdfScale((previousScale) => (previousScale + resizeScale)); (pdfScale > 2) ? setPdfFrame(" md:w-full ") : setPdfFrame(" md:w-1/2") } }> + </button>
                </div>
                <button className="	 text-2xl bg-sky-500/100 w-10 h-10 text-center rounded-full text-current hover:text-blue-500 active:text-blue-700 hover:bg-blue-500 hover:bg-opacity-10 dark:hover:text-blue-500 dark:active:text-blue-700 focus:outline-none focus:ring focus:ring-blue-500 focus:ring-opacity-35  p-0 mx-1 my-1 "
                    onClick={ () => { setDisplay((display) => (!display)) } }> { (display === true) ? "简" : "EN" }  </button>

                <Link className="ml-20" to={ (display === true) ? "/files/enResume.pdf" : "/files/cnResume.pdf" } target="_blank" download>
                    <button className="btn btn-info	 text-2xl text-center  text-current"
                    > <AiOutlineCloudDownload /> </button>
                </Link>

            </div>

            <div className={ "overflow-y-auto h-screen mx-auto" + pdfFrame }>

                <div className="Example container w-auto">
                    {/* <header>
                        <h1>English Resueme</h1>
                    </header> */}

                    { display && (<div className="Example__container">
                        <div className="Example__container__document ">
                            <Document file={ enfile } onLoadSuccess={ onDocumentLoadSuccess } options={ options } >
                                { Array.from(new Array(numPages), (el, index) => (
                                    <Page renderAnnotationLayer={ false } scale={ pdfScale } key={ `page_${index + 1}` } pageNumber={ index + 1 } />
                                )) }
                            </Document>
                        </div>
                    </div>) }

                    { !display && (<div className="Example__container">
                        <div className="Example__container__document ">
                            <Document file={ cnfile } onLoadSuccess={ onDocumentLoadSuccess } options={ options }>
                                { Array.from(new Array(numPages), (el, index) => (
                                    <Page renderAnnotationLayer={ false } scale={ pdfScale } key={ `page_${index + 1}` } pageNumber={ index + 1 } />
                                )) }
                            </Document>
                        </div>
                    </div>) }


                </div>
            </div>
        </>
    );
}

export default Resume;