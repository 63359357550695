import React, { useEffect, useRef } from 'react';

import { AboutUs, FindUs, Footer, Gallery, Header, Intro, SpecialMenu, Welcome, PostContent, PCLocalTest } from './container';
import { Navbar } from './components';
import './App.css';
import { MainPage, Projects, Contact, PostPage } from './pages';
import { ThemeProvider } from './components/NightTheme/ThemeContext';
import Background from './components/NightTheme/Background';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import { Dial } from './components/';
import ChatBot from './pages/Contact/ChatBot';
import ReUBCLIB from './pages/Lab/ReUBCLIB/ReUBCLIB';
//app is the home page
const App = () => (
	<>
		{/* 
		<Navbar />
		<Welcome />
		<Header />
		<AboutUs />
		<SpecialMenu />
		
		<Intro />
		<FindUs />
		<Footer /> */}
		<React.StrictMode>
			<BrowserRouter>


				<ThemeProvider>
					<Background>
						<Dial />
						<Routes>

							<Route path="/" element={ <MainPage /> } />
							{/* <Route path="projects" element={ <Projects /> } /> */ }
							<Route element={ <Projects /> } path="/projects" exact />

							<Route path="contact" element={ <Contact /> } />
							<Route path="*" element={
								<div className='container mx-auto text-center mt-32'>
									404 Not Found <Link className='underline' to={ "/" }> Back to yushengding.com</Link>
								</div> } />
							<Route element={ <PostPage /> } path="/posts" exact />
							<Route element={ <PostContent /> } path="/projects/:slug" />
							<Route element={ <PostContent /> } path="/posts/:slug" />




							<Route element={ <PCLocalTest /> } path="/lab/postTest" />
							<Route element={ <ChatBot /> } path='lab/bot' />
							<Route element={ <ReUBCLIB /> } path='lab/ReUBCLIB' />
						</Routes>


					</Background>
				</ThemeProvider>
			</BrowserRouter>

		</React.StrictMode>

	</>
)

//haven't created folder
const Games = () => (
	<>
		<Navbar />
		<p>nothing here yet</p>
		<Footer />

	</>
);

// export {
// 	App, Projects, Games,
// }

export default App;