import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";


// import required modules
import { EffectCards } from "swiper";
// image placeholder with text "not available"
const imageplaceholder = "https://via.placeholder.com/400";


const SwiperCard = ({ contentarray = [["Not Available", "Not Available", imageplaceholder], ["Not Available", "Not Available", imageplaceholder]] }) => {
    // const contentarray = [["Not Available", "Not Available", imageplaceholder], ["Not Available", "Not Available", imageplaceholder]]
    const example_ca = [
        ["Content Text Title", "Content", "image path"],
        ["Content Text Title", "Content", "image path"],
    ]
    let loopthis;
    return (
        <>
            <Swiper
                effect={ "cards" }
                grabCursor={ true }
                modules={ [EffectCards] }
                className="mySwiper my-8 "
            >

                {/* children !== undefined ?
                    loopthis = children
                    :
                    loopthis = example_ca
               */ }
                {
                    contentarray.map((item, index) => (
                        <SwiperSlide
                            key={ "Slides-key-index" + index }
                            className="p-6 bg-orange-500 dark:bg-orange-800 items-center flex rounded">
                            <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
                                <div>
                                    <h4 >
                                        { item[0] }
                                    </h4>
                                    <p>
                                        { item[1] }
                                    </p>
                                </div>

                                <div>
                                    <img
                                        className="mx-auto object-contain"
                                        src={ item[2] } alt="Sorry Not available yet, I will try to deal with this soon" />
                                </div>
                            </div>
                        </SwiperSlide>
                    ))
                }

            </Swiper>
        </>
    );

}

export default SwiperCard;  
