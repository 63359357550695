import React, { Component, useState } from 'react'
import QRCode from 'react-qr-code'
import { Link } from 'react-router-dom';
import { current } from 'tailwindcss/colors';
import { AiFillRobot, AiOutlineVerticalAlignTop } from 'react-icons/ai'
import { ChatBot } from '../../pages';

const Dial = () => {
    const [dialup, setDialup] = useState(false);
    const currentUrl = window.location.href;

    // the dial feature inspired by https://flowbite.com/docs/components/speed-dial/

    return (
        <div data-dial-init className="fixed right-6 bottom-6 group z-50">

            { dialup &&
                <div id="speed-dial-menu-click" className={ " flex-col items-center mb-4 space-y-2 flex" } >
                    <button
                        type="button" data-tooltip-target="tooltip-share" data-tooltip-placement="left" className="flex justify-center items-center w-[52px] h-[52px] text-gray-500 hover:text-gray-900 bg-white rounded-full border border-gray-200 dark:border-gray-600 shadow-sm dark:hover:text-white dark:text-gray-400 hover:bg-gray-50 dark:bg-gray-700 dark:hover:bg-gray-600 focus:ring-4 focus:ring-gray-300 focus:outline-none dark:focus:ring-gray-400">

                        <label htmlFor="my-modal-3" className="text-gray-500 hover:text-gray-900 dark:hover:text-white cursor-pointer" >
                            <svg aria-hidden="true" className="w-6 h-6 -ml-px " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z"></path>
                            </svg>
                            <span className="sr-only">QR Code For Current Page</span>
                        </label >
                        <input type="checkbox" id="my-modal-3" className="modal-toggle" />
                        <div className="modal ">
                            <div className="modal-box relative dark:bg-apple__text_black_strong">
                                <label htmlFor="my-modal-3" className="btn btn-sm btn-circle  absolute right-2 top-2">✕</label>
                                <h3 className="text-lg font-bold">Scan to Proceed with Browsing this Page</h3>
                                <a href={ currentUrl }>{ currentUrl }</a>
                                <QRCode className='mx-auto' value={ String(currentUrl) } />

                            </div>
                        </div>

                        <span className="sr-only">Share, QR Code For Current Page</span>
                    </button>
                    <div id="tooltip-share" role="tooltip" className="absolute z-10 invisible inline-block w-auto px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                        
                        <div className="tooltip-arrow" data-popper-arrow>Share</div>
                    </div>
                    <button type="button" onClick={ () => { window.print() } }

                        data-tooltip-target="tooltip-print" data-tooltip-placement="left" className="flex justify-center items-center w-[52px] h-[52px] text-gray-500 hover:text-gray-900 bg-white rounded-full border border-gray-200 dark:border-gray-600 shadow-sm dark:hover:text-white dark:text-gray-400 hover:bg-gray-50 dark:bg-gray-700 dark:hover:bg-gray-600 focus:ring-4 focus:ring-gray-300 focus:outline-none dark:focus:ring-gray-400">
                        <svg aria-hidden="true" className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5 4v3H4a2 2 0 00-2 2v3a2 2 0 002 2h1v2a2 2 0 002 2h6a2 2 0 002-2v-2h1a2 2 0 002-2V9a2 2 0 00-2-2h-1V4a2 2 0 00-2-2H7a2 2 0 00-2 2zm8 0H7v3h6V4zm0 8H7v4h6v-4z" clipRule="evenodd"></path></svg>
                        <span className="sr-only">Print</span>
                    </button>
                    <div id="tooltip-print" role="tooltip" className="absolute z-10 invisible inline-block w-auto px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                        
                        <div className="tooltip-arrow" data-popper-arrow>Print</div>
                    </div>

                    <label htmlFor="chatbot" className="hover:cursor-pointer flex justify-center items-center w-[52px] h-[52px] text-gray-500 hover:text-gray-900 bg-white rounded-full border border-gray-200 dark:border-gray-600 shadow-sm dark:hover:text-white dark:text-gray-400 hover:bg-gray-50 dark:bg-gray-700 dark:hover:bg-gray-600 focus:ring-4 focus:ring-gray-300 focus:outline-none dark:focus:ring-gray-400">
                        <AiFillRobot size={ 22 } />
                        <span className="sr-only">ChatBot</span>
                    </label>

                    <div id="tooltip-download" role="tooltip" className="absolute z-10 invisible inline-block w-auto px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                        
                        <div className="tooltip-arrow" data-popper-arrow>ChatBot</div>
                    </div>


                    <button onClick={ () => { document.body.scrollTop = document.documentElement.scrollTop = 0; } }
                        type="button" data-tooltip-target="tooltip-copy" data-tooltip-placement="left" className="flex justify-center items-center w-[52px] h-[52px] text-gray-500 hover:text-gray-900 bg-white rounded-full border border-gray-200 dark:border-gray-600 dark:hover:text-white shadow-sm dark:text-gray-400 hover:bg-gray-50 dark:bg-gray-700 dark:hover:bg-gray-600 focus:ring-4 focus:ring-gray-300 focus:outline-none dark:focus:ring-gray-400">
                        <AiOutlineVerticalAlignTop size={ 22 } />
                        <span className="sr-only">Top</span>
                    </button>
                    <div id="tooltip-copy" role="tooltip" className="absolute z-10 invisible inline-block w-auto px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                        Top
                        <div className="tooltip-arrow" data-popper-arrow></div>
                    </div>

                </div>
            }
            <button type="button" onClick={ () => (setDialup(!dialup)) }
                data-dial-toggle="speed-dial-menu-click" data-dial-trigger="click" aria-controls="speed-dial-menu-click" aria-expanded="false" className="flex items-center justify-center text-white bg-blue-700 rounded-full w-14 h-14 hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800">
                <svg aria-hidden="true" className="w-8 h-8 transition-transform group-hover:rotate-45" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                <span className="sr-only">Open actions menu</span>
            </button>

            <input type="checkbox" id="chatbot" className="modal-toggle" />
                    <div className="modal">
                        <div className="modal-box relative">
                            <label htmlFor="chatbot" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                            <ChatBot />
                        </div>
                    </div>

        </div >


    )

}

export default Dial 