// loading neccessary modules and css files
import React, { useState, useEffect } from "react";
import { AboutUs, FindUs, Footer, Gallery, Header, Intro, SpecialMenu, Welcome, PostContent } from '../../container';
import { Navbar } from "../../components";
// import { PostPage } from "../";
import { urlFor, client } from "../../client";
import { Link } from "react-router-dom";
import CardNew from "../../container/ContentBox/CardNew";
import { BsFillArrowRightSquareFill } from "react-icons/bs";
import './Projects.css';

const imageReplace = (aboutimage) => {
    let hasImg = true;
    let imageaddress = "";
    const placeholderSrc
        = 'https://via.placeholder.com/200x200?text=Intentional+Blank';
    try {
        imageaddress = urlFor(aboutimage).width(300).url();
    }
    catch (error) {
        hasImg = false;
    }
    imageaddress = hasImg ? imageaddress : placeholderSrc;
    return imageaddress;
}



const Projects = () => {
    const [abouts, setAbouts] = useState([]);
    // set an empty state for the post data

    useEffect(() => {
        const query = '*[_type == "abouts"] | order(_createdAt desc)';
        // asc is ascending order, desc is descending order
        client.fetch(query).then((data) => {
            setAbouts(data).catch(console.error);
        });
    }, []);


    // useEffect Hook is to do with react life cycle. Basically, it means
    // On the first frame the page loads, it will fetch the data
    // and then doesn't fetch it again unless the slug changes
    // Sanity.io: Slug is a schema type for slugs, 
    // typically used to create unique URLs.
    const timeline_Component = () => {
        return (
            abouts &&
            abouts.map((about, index) => (

                <li className=" mb-8 "
                    key={ "li of " + about.projectTitle + " + TL: " + index }>
                    <div
                        className="md:flex xl:items-center md:justify-between"
                    >
                        <div className="flex flex-start items-center pt-3">
                            <div className="bg-gray-900 dark:bg-slate-200 w-2 h-2 rounded-full -ml-1 mr-3"></div>
                            <p className="text-gray-500 text-sm"> { String(about._createdAt).substring(0, 10) } </p>

                        </div>
                        <div className="clear-both inline-block md:w-3/4">
                            <div className={ (index % 2 === 0 ? "float-left " : "float-right ") + " md:w-52	 w-full m-0" }>
                                <div className="mt-0.5 ml-4 mb-6">

                                    <Link to={ "/projects/" + about.slug.current } key={ about.slug.current + "Title" }
                                        className="mb-1.5 "
                                    >
                                        {/* link-underline-animated-black link-underline-animated */ }
                                        <h4 className="text-gray-800 font-semibold text-xl  ">
                                            { about.projectTitle }
                                        </h4>
                                    </Link>

                                    <h5 className="text-gray-800 text-sm mb-1.5">{ about.projectSubtitle }</h5>

                                    <p className="text-gray-500 mb-3 text-xs">{ about.projectDescription }</p>
                                    <Link to={ "/projects/" + about.slug.current } key={ about.slug.current + "Details" }
                                        className="link-underline-animated-black link-underline-animated dark:text-slate-200"
                                    >
                                        <p>  { "Details" } <BsFillArrowRightSquareFill size={ 20 } className="inline-block " /> </p>
                                    </Link>

                                </div>

                            </div>
                            <img className={ (index % 2 === 0 ? "float-right " : "float-left ") + " md:w-52 w-full m-0" }

                                src={ imageReplace(about.image) } alt={ about.imagealt } />

                        </div>
                        {/* <Link to={ "/projects/" + about.slug.current } key={ about.slug.current }
                            className="text-gray-500 mb-3 md:float-right w-full md:w-1/12 block"
                        >
                            <button key={ index } className="btn btn-primary bg-apple__blue dark:bg-apple__blue_dark w-full ">
                                    custom__button
                                { "Go" }
                            </button>
                        </Link> */}

                        {/* bg-slate-300 w-full h-4 */ }
                    </div>
                    <div className="clear-both" />
                </li>
            )
            )
        );
    };



    const [toggleTLCD, setToggleTLCD] = useState(true);
    const cards_Component = () => {
        return (
            abouts &&
            abouts.map((about, index) => (
                <CardNew about={ about } key={ index } />
            )
            )
        );


    };

    return (
        <div className=" ">
            <Navbar />
            <div className="hero min-h-screen "
                style={ { /*backgroundImage: `url("https://live.staticflickr.com/7236/7180897776_9e91c4d83a_o_d.jpg")` */} }>
                <div className="hero-overlay bg-opacity-40"></div>
                <div className="hero-content text-center text-neutral-content">
                    <div className="max-w-md">
                        <h1 className="mb-5 text-5xl font-bold text-apple__text_black_strong dark:text-apple__text_gray_dark_strong">Hello there</h1>
                        <p className="mb-5 text-apple__text_black_strong dark:text-apple__text_gray_dark_strong">Here, you can take a look at my recent projects.</p>
                        <ul>
                            <li>
                                <p className="mb-5 text-apple__text_black_strong dark:text-apple__text_gray_dark_strong">If you want to look at my progress of developing different projects over time, you can select Timeline Mode.</p>
                            </li>
                            <li>
                                <p className="mb-5 text-apple__text_black_strong dark:text-apple__text_gray_dark_strong">If you want to breifly scan through all of my projects, you can select Card Mode.</p>
                            </li>
                        </ul>
                        <a href="#start"
                        // onClick={ e => {
                        //     let hero = document.getElementById("start");
                        //     e.preventDefault();  // Stop Page Reloading
                        //     hero && hero.scrollIntoView();
                        // } }
                        >
                            <button className="animate-bounce btn glass btn-circle">Start</button>
                        </a>

                    </div>
                </div>
            </div>

            <div className="container w-full mx-auto mt-10 ">


                <div id="start" className="tabs tabs-boxed swap mb-5"
                    onClick={ (e) => {
                        setToggleTLCD(!toggleTLCD);
                        !toggleTLCD ? e.currentTarget.firstChild.classList.add('tab-active')
                            : e.currentTarget.firstChild.classList.remove('tab-active');
                        toggleTLCD ? e.currentTarget.lastChild.classList.add('tab-active')
                            : e.currentTarget.lastChild.classList.remove('tab-active');

                    } }>
                    <a className={ "tab dark:text-apple__text_black_strong focus:tab-active active:tab-active tab-active" }
                    >Timeline Mode</a>
                    <a className={ "tab dark:text-apple__text_black_strong focus:tab-active active:tab-active" }>Card Mode</a>
                </div>

                <div className="container mx-auto xl:w-1/2">
                    {
                        toggleTLCD && <ol className="border-l border-gray-300 dark:border-green-900 mx-5">
                            
                            { timeline_Component() }

                        </ol>
                    }
                </div>
                {
                    !toggleTLCD && <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                        {/* grid is an equivalent to display: grid; 
                        grid-cols-n creates n grid column; 
                        md: and lg: are breakpoints that indicate 
                        the situation that are larger than (min-width) lg and md. */}
                        { cards_Component() }
                    </div>
                }
            </div>
            <Footer />
        </div>);

};

export default Projects;


{/* { abouts &&
                                abouts.map((about, index) => (
                                    <li>
                                        <div class="flex flex-start items-center pt-3">
                                            <div class="bg-gray-300 w-2 h-2 rounded-full -ml-1 mr-3"></div>
                                            <p class="text-gray-500 text-sm"> Date will be added soon </p>
                                        </div>
                                        <div class="mt-0.5 ml-4 mb-6">
                                            <h4 class="text-gray-800 font-semibold text-xl mb-1.5">{ about.projectTitle }</h4>
                                            <h5 class="text-gray-800 font-semibold text-xs mb-1.5">{ about.projectSubtitle }</h5>

                                            <p class="text-gray-500 mb-3">{ about.projectDescription }</p>
                                        </div>

                                        <Link to={ "/projects/" + about.slug.current } key={ about.slug.current }>
                                            <span key={ index }>
                                                <span>
                                                    <h2> Detail - { about.projectTitle }</h2>
                                                </span>
                                            </span>
                                        </Link>
                                    </li>
                                )) } */}