import React, { useState, useEffect } from "react";
// import { images } from '../../constants';
import { GoDash } from "react-icons/go";
import "./SubHeading.css"
import Typewriter from 'typewriter-effect';

const SubHeading = ({ start, title }) => {

	return (
		<div className="mb-1 text-2xl p__cormorant underline">
			{/* <p className='p__cormorant underline typewriter'>{ title } </p> */ }
			<div className="md:last-of-type:inline-flex block dark:text-yellow-700">{ start }&nbsp;
				<Typewriter
					options={ {
						strings: title.split(', '),
						autoStart: true,
						loop: true,
					} }
				/>
			</div>
			{/* <img src={images.spoon} alt="spoon" className='spoon__img' /> */ }
			{/* <GoDash color='white' size={60}/> */ }
		</div>
	);
};

export default SubHeading;
