// loading neccessary modules and css files
import React, { useState, useEffect, Suspense, lazy } from 'react';
import { useParams } from "react-router-dom";
import { urlFor, client } from "../../client";
import { Navbar, Loading } from '../../components';
import { Footer, SwiperCard } from '../../container';
// import { MDXProvider } from '@mdx-js/react'
import Markdown from 'markdown-to-jsx';
import { compile, evaluate, compileSync, run } from "@mdx-js/mdx"
import * as runtime from "react/jsx-runtime"
// import ReactMarkdown from 'react-markdown'
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards } from "swiper";
import { Carousel } from '../../container';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import "./PostContent.css";
// https://mdxjs.com/docs/using-mdx/#props fixed dynamic props by this 
const components = {
    Navbar: () => (<Navbar />),
    SwiperCard: (props) => (<SwiperCard {...props}/> ),
    Carousel: () => (<Carousel />),
}
const PostContent = () => {
    const [postData, setPostData] = useState(null);
    // set an empty state for the post data
    const { slug } = useParams();
    // Returns an object of key/value pairs of the 
    // dynamic params from the current URL that were 
    // matched by the route path.
    const [exports, setExports] = useState({ default: runtime.Fragment });

    // useEffect(() => {
    //     client
    //         .fetch(
    //             `*[slug.current == $slug]`,
    //             { slug }
    //         )
    //         .then((data) => setPostData(data[0]))
    //         .catch(console.error)
    //         .then(() => evaluate(postData.markdownFile, { ...runtime }).then((exports) => setExports(exports)))


    // }, [slug]);

    useEffect(() => {
        client
            .fetch(
                `*[slug.current == $slug]`,
                { slug }
            )
            .then((data) => {
                setPostData(data[0]);
                evaluate(data[0].markdownFile, { useDynamicImport: true, ...runtime, Swiper: [Swiper] }).then((exports) => setExports(exports))
                    .catch((error) => console.log(String(error))); // temp fix
            })
            .catch(console.error);
    }, [slug]);


    // useEffect Hook is to do with react life cycle. Basically, it means
    // On the first frame the page loads, it will fetch the data
    // and then doesn't fetch it again unless the slug changes
    // Sanity.io: Slug is a schema type for slugs, 
    // typically used to create unique URLs.
    //  Some of testing query from online blogs... 
    //{
    //     projectTitle,
    //     slug,
    //     mainImage{
    //       asset->{
    //         _id,
    //         url
    //        }
    //      },
    //    body,
    //   "name": author->name,
    //   "authorImage": author->image
    //  }
    const Placeholder = () => (<>
        <Navbar />
        <div className='container md:mx-14'>
            <Loading skeleton={ "widget" } />
            <Loading skeleton={ "image" } />
            <Loading skeleton={ "text" } />
            <Loading skeleton={ "text" } />
            <Loading skeleton={ "text" } />
            <Loading skeleton={ "text" } />
        </div>
        <Footer />
    </>
    )
    if (!postData) {
        return (<Placeholder />)
    }

    // hasImg and imageaddress are used to check if the post has an image or not, 
    // if not, it will use a placeholder image

    ////////////////////////////////
    // The HTML tags part! 

    // useEffect(() => {
    //     evaluate(postData.markdownFile, { ...runtime }).then((exports) => setExports(exports));
    // }, [postData]);




    // This would be shown while the post is loading from CMS 
    // This is for my debugging purposes (check if the data is being fetched correctly)

    let hasImg = true;

    let imageaddress = "";
    const placeholderSrc
        = 'https://via.placeholder.com/400x400?text=Intentional+Blank';
    try {
        imageaddress = urlFor(postData.image).url();
    }
    catch (error) {
        hasImg = false;
    }
    imageaddress = hasImg ? imageaddress : placeholderSrc;

    const { markdownFile } = postData;

    const Content = exports.default;
    // console.log(markdownFile);
    return (
        <>
            <Navbar />

            <div className="hero min-h-screen bg-base-200 dark:bg-slate-900">
                <div className="hero-content flex-col lg:flex-row-reverse">
                    <img src={ imageaddress }
                        alt={ postData.imagealt }
                        className="md:max-w-sm rounded-lg shadow-2xl w-full" />
                    <div>
                        <h1 className="text-5xl font-bold">{ postData.projectTitle }</h1>
                        <p className="py-6">{ postData.projectSubtitle }</p>
                        <p className="py-6">{ postData.projectDetail }</p>
                    </div>
                </div>
            </div>
            {/* components={ components } */ }


            {/* <MDXProvider >
                { console.log(run(Mdxcontent()))}            
            </MDXProvider> */}
            {/* <Suspense fallback={ <Loading /> }>
                { run(Mdxcontent()) }
            </Suspense> */}

            <div className='container mx-auto px-2 md:px-10' id='postContent'>

                {
                    <Content components={ components } /> ||


                    <Markdown className="markdownRender">
                        { markdownFile }
                    </Markdown> }
            </div>



            <Footer />
        </>
    )
}
export default PostContent;
