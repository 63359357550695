import React from 'react';
import { FaSun, FaMoon} from "react-icons/fa";
import { ThemeContext } from './ThemeContext';

const Toggle = () => {
    const { theme, setTheme } = React.useContext(ThemeContext);

    return (
        <div className="transition duration-500 ease-in-out rounded-full p-2" >
            {theme === 'dark' ? (
                <FaSun
                    onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
                    className="p-1  text-gray-500 dark:text-gray-400 text-3xl cursor-pointer  text-center rounded-full text-current  hover:bg-blue-500 hover:bg-opacity-10 dark:hover:text-blue-500 dark:active:text-blue-700 focus:outline-none focus:ring focus:ring-blue-500 focus:ring-opacity-35"
                />
            ) : (
                // hover:text-blue-500 active:text-blue-700
                    <FaMoon
                        onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
                        className="p-1  text-gray-500 dark:text-gray-400 text-3xl cursor-pointer  text-center rounded-full text-current  hover:bg-blue-500 hover:bg-opacity-10 dark:hover:text-blue-500 dark:active:text-blue-700 focus:outline-none focus:ring focus:ring-blue-500 focus:ring-opacity-35"
                    />
                )}
        </div>
    );
};

export default Toggle;
