import React, { useEffect } from 'react'
import { Suspense, useCallback, useMemo, useRef } from 'react';
import { Loading } from '../../components';
import { Canvas, extend, useFrame, useLoader, useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { useSprings, a } from '@react-spring/three'

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Welcome.css';
// import LogoAni from './LogoAni';


// from https://codesandbox.io/s/4g105j?file=/src/App.tsx:1899-2034
// const AnimationCanvas = () => {
// };
const number = 35
const colors = ['#A2CCB6', '#FCEEB5', '#EE786E', '#e0feff', 'lightpink', 'lightblue']
const random = (i) => {
    const r = Math.random()
    return {
        position: [100 - Math.random() * 200, 100 - Math.random() * 200, i * 1.5],
        color: colors[Math.round(Math.random() * (colors.length - 1))],
        scale: [1 + r * 14, 1 + r * 14, 1],
        rotation: [0, 0, THREE.MathUtils.degToRad(Math.round(Math.random()) * 45)]
    }
}
const data = new Array(number).fill(null).map(() => {
    return {
        color: colors[Math.round(Math.random() * (colors.length - 1))],
        args: [0.1 + Math.random() * 9, 0.1 + Math.random() * 9, 10]
    }
})
function Content() {
    const [springs, api] = useSprings(number, (i) => ({
        from: random(i),
        ...random(i),
        config: { mass: 20, tension: 150, friction: 50 }
    }))

    useEffect(() => void setInterval(() => api.start((i) => ({ ...random(i), delay: i * 40 })), 3000), [])

    return (
        <>
            { data.map((d, index) => (
                <a.mesh key={ index } { ...springs[index] } castShadow receiveShadow>
                    <boxGeometry attach="geometry" args={ d.args } />
                    <a.meshStandardMaterial attach="material" color={ springs[index].color } roughness={ 0.75 } metalness={ 0.5 } />
                </a.mesh>
            )) }
        </>
    )
}

function Lights() {
    return (
        <group>
            <pointLight intensity={ 0.3 } />
            <ambientLight intensity={ 2 } />
            <spotLight
                castShadow
                intensity={ 0.2 }
                angle={ Math.PI / 7 }
                position={ [150, 150, 250] }
                penumbra={ 1 }
                shadow-mapSize-width={ 2048 }
                shadow-mapSize-height={ 2048 }
            />
        </group>
    )
}

const Welcome = () => {
    const [springs, api] = useSprings(number, (i) => ({
        from: random(i),
        ...random(i),
        config: { mass: 20, tension: 150, friction: 50 }
    }))

    useEffect(() => void setInterval(() => api.start((i) => ({ ...random(i), delay: i * 40 })), 3000), [])



    return (
        <>

            <div className='anim hero'>
                <Suspense fallback={ <Loading skeleton='image'/> }>

                    {/* <AnimationCanvas /> */ }
                    {/* <Canvas camera={ { pos: [0, 9, 1] } }>
                        <pointLight position={ [10, 10, 10] } />
                        <mesh>
                            <sphereGeometry />
                            <meshStandardMaterial color="hotpink" />
                        </mesh>
                    </Canvas> */}
                    <Canvas linear flat shadows camera={ { position: [0, 0, 100], fov: 100 } }>
                        <Lights />
                        <Content />
                    </Canvas>

                </Suspense>

                <div className="hero-content text-center text-neutral-content">
                    <div className="max-w-md">
                        <h1 className="mb-5 text-5xl font-bold mix-blend-difference	">Welcome</h1>
                        <p className="mb-5 mix-blend-difference	">This is Yusheng Ding's Portfolio</p>
                    </div>
                </div>
                {/* <div className="hero-overlay bg-opacity-90"></div>
 */}

            </div>
        </>
        // <>
        //     <LogoAni />
        // </>
    );
};

export default Welcome;
