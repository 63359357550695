import React, { useState } from "react";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
// import { experiences } from "./Experiences";
import { Loading } from "../../components";
import { MdWork } from 'react-icons/md';
import {SiGooglescholar} from 'react-icons/si';
import { Suspense } from "react";




const Timeline = ({ experiences }) => {
    return (
        <>
            <Suspense fallback={ <Loading skeleton={"image"}/> }>
                <VerticalTimeline 
                lineColor={'#7D7D7D '}
                >
                    {
                        experiences.map(
                            (experience, index) => (
                                < VerticalTimelineElement key={ `experience-${index}` }
                                    contentArrowStyle={ { borderRight: "7px solid #232631" } }
                                    date={ experience.date }
                                    dateClassName={ 'dark:text-apple__text_gray_dark' }
                                    icon={ experience.type === "work" ? <MdWork /> : <SiGooglescholar /> }
                                    iconStyle={{background: experience.iconBg}}
                                    style={ { visibility: 'visible', } }

                                >
                                    <h3 className='vertical-timeline-element-title text-[24px] font-bold dark:text-apple__text_black'>
                                        { experience.title }
                                    </h3>
                                    <h4 className="vertical-timeline-element-subtitle  dark:text-apple__text_black">{ experience.company_name }</h4>

                                    <p
                                        className='text-[16px] font-semibold dark:text-apple__text_black'
                                        style={ { margin: 0 } }
                                    >
                                        { experience.points.map((point) => {
                                            return (
                                                <li className='list-disc list-inside'>
                                                    { point }
                                                </li>
                                            )
                                        }

                                        ) }
                                    </p>

                                </VerticalTimelineElement >

                            )
                        )
                    }

                </VerticalTimeline>
            </Suspense>
        </>
    )
}

export default Timeline; 