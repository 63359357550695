import React, { Component } from 'react'
import { urlFor, client } from "../../client";
// import { click } from '@testing-library/user-event/dist/click';
import { Link } from "react-router-dom";


export default class CardNew extends Component {

    constructor (props) {
        super(props);
        this.state = { clicked: false, opacity: 0 };
        // this.updateState = this.updateState.bind(this);

    }
    // updateState = () => {
    //     this.setState({
    //         clicked: !this.state.clicked,
    //         opacity: this.state.opacity === 0 ? 0.7 : 0,
    //     });
    // }


    render() {

        const placeholderSrc = 'https://via.placeholder.com/200x200?text=Intentional+Blank'
        const { about } = this.props;

        const { projectTitle, projectSubtitle, projectDescription, projectDetail, link, image, imagealt, backupArray, selected, markdownFile } = about
        let hasImg = true;
        try {
            urlFor(image).url();
        }
        catch (error) {
            hasImg = false;
        }
        const maxStrlen = 60; 
        return (
            <>
                <div className="card w-full glass bg-base-100 dark:bg-apple__text_black shadow-xl md:pb-10 pb-20 " key={ "CardNew: "+ about.slug.current } >
                    {/* {onClick={ alert(projectName)}  } */ }

                    <div className='grid min-h-0 grid-rows-[auto_minmax(0,1fr)] overflow-hidden' > {/* grid-rows-2  w-full */ }
                        <figure className='h-48 '>{
                            hasImg ?
                                <img className='h-48 w-auto object-contain' src={ urlFor(image).url() } alt={ imagealt } />
                                :
                                <img className='h-48 w-auto object-contain' src={ placeholderSrc } alt="No image, image placeholder" />
                            /* md:h-48 h-12 object-contain */
                        }
                        </figure>

                        <div className='card-body md:p-8 p-1 ' >

                            <h4 className='card-title mb-0' >
                                { about.projectTitle.length > maxStrlen ? (about.projectTitle.substring(0, maxStrlen) + "...") : about.projectTitle }
                            </h4>
                            <p >
                                { about.projectSubtitle.length > maxStrlen ? (about.projectSubtitle.substring(0, maxStrlen) + "...") : about.projectSubtitle }
                            </p>
                            <p >
                                { about.projectDescription.length > maxStrlen ? (about.projectDescription.substring(0, maxStrlen) + "...") : about.projectDescription }
                            </p>

                        </div>
                        <Link className='card-actions absolute bottom-5 right-5 '
                            to={ "/projects/" + about.slug.current } key={ about.slug.current }>
                            <button className="btn btn-primary bg-apple__blue dark:bg-apple__blue_dark w-20">Go</button>
                        </Link>

                        {/* { selected && <CardInfo title={ title } subTitle={ subTitle } link={ link } /> } */ }
                    </div>
                </div>
            </>
        )
    }
}
