const experiences = [
    {
        title: "Master of Arts (MA)",
        company_name: "The University of British Columbia - Vancouver, BC, Canada",
        type: "Education",

        // icon: shopify,
        iconBg: "#383E56",
        date: "Sept 2022 - Present",
        points: [
            "iSchool",

            "Specialization in Interaction Design, UX Design",
        ],
    },
    {
        title: "Academic Tutor",
        company_name: "SavvyUni UTM division - Mississauga, Canada",
        type: "work",
        // icon: starbucks,
        iconBg: "#383E56",
        date: "Sept 2021 - Dec 2022",
        points: [
            "Provided academic assignment to multiple classes composed of 15-30 students in varies areas such as introduction to computer science, introduction to communication theory, semiotics, design thinking, web design, etc.",
            "Communicating with students periodically about their academic performance and giving them their suggestions for improvements. ",
            "Maintain good customer relationship with the students by actively providing helps they need and look for potential areas to help even more. ",
        ],
    },
    {
        title: "Unity 3D Developer & Tutor",
        company_name: "Cloud Times Education - Mississauga, ON, Canada",
        type: "work",

        // icon: tesla,
        iconBg: "#E6DEDD",
        date: "June 2021 - Nov 2022",
        points: [
            "Provided academic assistance to multiple classes composed of 3-5 students regarding Unity 3D concepts (C# programming language, animation, etc.) and game design (MDA framework)",
            "Effectively communicated key concepts to enhance design knowledge and programming language comprehension, developing Unity 3D game assets and other information tools for students to refer to",
            "Produced marketing videos for courses, implementing creative design tactics to attract student interest",
        ],
    },
    {
        title: "Honours Bachelor of Science (HBSc)",
        company_name: "University of Toronto - Toronto, ON, Canada",
        type: "Education",

        // icon: shopify,
        iconBg: "#383E56",
        date: "Sept 2017 - Apr 2021 ",
        points: [
            "Major in Computer Science and New Media Studies",
        ],
    },

    {
        title: "Front End Developer Intern",
        company_name: "Research System, University of Toronto's Hatchery - Toronto, ON, Canada",
        type: "work",

        // icon: shopify,
        iconBg: "#383E56",
        date: "July 2021 - Aug 2021 ",
        points: [
            "Managed design initiatives for website's front-end, hosted on Weebly, diligently maintaining and administering necessary updates to reflect current events and information",
            "Collaboratively developed hatchery pitch, facilitating PPT arrangements and designs; orchestrated presentation, outlined product specifications and objectives, and successfully initiated venture capital interest",
            "Skillfully leveraged technical proficiency in Adobe XD to create dynamic, interactive and high-fidelity product demos",
            "Analyzed user persona by performing market research on potential clients, examining markets by comparing potential competitors' strategies",
        ],
    },
    {
        title: "Assistant Product Manager",
        company_name: "Musiness Co., Ltd. - Shanghai, China",
        type: "work",

        // icon: meta,
        iconBg: "#383E56",
        date: "May 2019 - Aug 2019 ",
        points: [
            "Collaborated with social media marketing team to implement innovative strategies and increase promotional reach by posting daily social media content and articles, successfully increasing web traffic by 20%",
            "Developed multiple promotional posters and banners for website's front page and sidebars, utilizing front-end design strategies to attract customers",
            "Designed and facilitated online shopping cart functionalities using Adobe XD and Photoshop, ensuring operational efficiency while enhancing user experience",
            "Conducted SEO analysis through Baidu Analytics to gain informed understanding of conversion and bounce rates",
            "Performed extensive testing procedures on new features using backend dashboard",
        ],
    },
];


export { experiences }; 