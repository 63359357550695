import React from 'react';
import { AiFillGithub, AiFillLinkedin, AiFillInstagram, AiFillTwitterSquare, AiFillTwitterCircle, AiFillWechat } from "react-icons/ai";
import { MdOutlineRestaurantMenu } from 'react-icons/md';
import { images } from '../../constants/';



import './Footer.css';

const Footer = () => (
  <>
    {/* <a href='https://github.com/davidysding'>
      <AiFillGithub fontSize={ 27 } className="dark:text-white" />
    </a>
    <a href='https://www.linkedin.com/in/davidysding/'>
      <AiFillLinkedin fontSize={ 27 } className="dark:text-blue-400" />
    </a> */}
    <footer className="p-4 bg-white sm:p-6 dark:bg-gray-900">
      <div className="md:flex md:justify-between">
        <div className="mb-6 md:mb-0">
          <a href="/" className="flex items-center">
            <img src={ images.logo } className="h-8 mr-3" alt="Yusheng Ding Logo" />
            <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Yusheng</span>
          </a>
        </div>
        <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
          <div>
            <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Resources</h2>
            <ul className="text-gray-600 dark:text-gray-400">
              <li className="mb-4">
                <a href="https://yushengding.com/" className="hover:underline">Yusheng</a>
              </li>
              <li>
                <a href="https://davidysding.github.io/" className="hover:underline">Old Portfolio</a>
              </li>
            </ul>
          </div>
          <div>
            <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Follow us</h2>
            <ul className="text-gray-600 dark:text-gray-400">
              <li className="mb-4">
                <a href="https://github.com/davidysding" className="hover:underline ">Github</a>
              </li>
              <li>
                <a href="https://discordapp.com/users/197527265100300288" className="hover:underline">Discord</a>
              </li>
            </ul>
          </div>
          <div>
            <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Legal</h2>
            <ul className="text-gray-600 dark:text-gray-400">
              <li className="mb-4">
                <a href="#" className="hover:underline">Privacy Policy</a>
              </li>
              <li>
                <a href="#" className="hover:underline">Terms &amp; Conditions</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
      <div className="sm:flex sm:items-center sm:justify-around">
      {/* justify-between */}
        <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2022-2023 <a href="https://yushengding.com/" className="hover:underline">Yusheng Ding</a>. All Rights Reserved.
        </span>
        <div className="flex mt-4 space-x-6 sm:justify-center sm:mt-0">
          <a href="https://github.com/davidysding" className="text-gray-500 hover:text-gray-900 dark:hover:text-white">
            <AiFillGithub fontSize={ 27 } className="w-5 h-5" fill="currentColor" />
            <span className="sr-only">GitHub</span>
          </a>
          <a href="https://instagram.com/yahwooday" className="text-gray-500 hover:text-gray-900 dark:hover:text-white">
            <AiFillInstagram fontSize={ 27 } className="w-5 h-5" fill="currentColor" />
            <span className="sr-only">Instagram page</span>
          </a>
          <a href="https://twitter.com/yahwooday" className="text-gray-500 hover:text-gray-900 dark:hover:text-white">
            <AiFillTwitterCircle fontSize={ 27 } className="w-5 h-5" fill="currentColor" />
            <span className="sr-only">Twitter page</span>
          </a>
          <label htmlFor="wechatqr" className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-apple__text_gray_dark cursor-pointer">
            <AiFillWechat fontSize={ 27 } className="w-5 h-5" fill="currentColor" />
            <span className="sr-only">WeChat account</span>
          </label>


        </div>

        <input type="checkbox" id="wechatqr" className="modal-toggle" />
        <div className="modal">
          <div className="modal-box relative dark:bg-apple__text_black_strong select-none">
            <label htmlFor="wechatqr" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
            <h3 className="text-lg font-bold">Use WeChat to Scan QR Code</h3>
            <img className='select-none' src={ images.wcqrcode } alt="image of WeChat QR code, Scan to add contact" />
          </div>
        </div>

      </div>
    </footer>

  </>
);

export default Footer;
