import React, { useState, useEffect } from "react";
import './PostPage.css';

import { AboutUs, FindUs, Footer, Gallery, Header, Intro, SpecialMenu, Welcome, Carousel, PostContent } from '../../container';
import { Navbar } from "../../components";
import { urlFor, client } from "../../client";
import { Link } from "react-router-dom";


const PostPage = () => {
    const [abouts, setAbouts] = useState([]);

    useEffect(() => {
        const query = '*[_type == "abouts"]';

        client.fetch(query).then((data) => {
            setAbouts(data).catch(console.error);
        });
    }, []);
    return (
        <>
            <Navbar />
            <Carousel />

            {/* { abouts.map((about, index) => (
                <div
                    key={ about.projectTitle + index }
                >
                    <PostContent post={ about } />

                   <img src={ urlFor(about.image) } alt={ about.projectTitle } /> 
                </div>
            )) } */}
            <div>
                <h2>Blog Posts</h2>
                <h3>Welcome to my blog posts page!</h3>
                <div>
                    { abouts &&
                        abouts.map((about, index) => (
                            <Link to={ "/" + about.slug.current } key={ about.slug.current }>
                                <span key={ index }>
                                    <span>
                                        <h2>{ about.projectTitle }</h2>
                                    </span>
                                </span>
                            </Link>
                        )) }
                </div>
            </div>


            <Gallery />

            {/* post={ about } */ }
            <Footer />

        </>
    );

};

export default PostPage;