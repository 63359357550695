import React, { useState, useEffect } from 'react';
import { urlFor, client } from "../../client";
import {Carousel} from '../';
import './AboutUs.css';

const AboutUs = () => {
  // const [abouts, setAbouts] = useState([]);

  // useEffect(() => {
  //   const query = '*[_type == "abouts"]';

  //   client.fetch(query).then((data) => {
  //     setAbouts(data);
  //   });
  // }, []);

  return (
    <>
      <Carousel />
    </>
  );
};



export default AboutUs;
